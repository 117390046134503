import { render, staticRenderFns } from "./workspace-prompts.vue?vue&type=template&id=16176d02&scoped=true"
import script from "./workspace-prompts.vue?vue&type=script&lang=js"
export * from "./workspace-prompts.vue?vue&type=script&lang=js"
import style0 from "./workspace-prompts.vue?vue&type=style&index=0&id=16176d02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16176d02",
  null
  
)

export default component.exports